import { GenerateTransactionType } from "./cosmos/cosmosresponse"
/**
 * submissions.ts
 * Contains definitions for server side abstractions of Tenaska submissions and those used the transactions component.
 * 
 * UI vs. Base Resource Submissions
 * The UI variants (i.e. AncillaryUISubmission vs. AncillaryResourceSubmission) have UI state properties like `selected` and `active`
 * which are redundant once on the wire (i.e. we only send "selected" items, the rest are discarded).
 * 
 */

export interface ClipboardData {
  type : SubType,
  bidDate : string,
  data : AnyResourceSubmission
}

export enum SubType {
    cop  = 'cop',
    ancillary = 'ancillaries',
    realTime = 'realtime'
}

export enum DataType{
  price = 'price',
  volume = 'volume',
}
  
export enum LoadType {
  load = 'Controllable Load',
  gen = 'Generator Configuration'
}

export interface Offer{
  volume: number;
  price: number;
}

export interface BaseIntervalData{
  hourEnding: number
  assertNullValues : boolean
}

export type BaseFields = keyof BaseIntervalData;
export type AncillaryFields = 'rrs'|'ecrs'|'nonSpin'|'regUp'|'regDown';

export type AncillaryInterval = BaseIntervalData & {
  [key in AncillaryFields]? : Offer;
}

export interface CopInterval extends BaseIntervalData{
  status?: string;
  lel? :number;
  lsl? : string;
  hsl? : number;
  hel? :number;
  rrs?: number;
  regUp? : number;
  regDown? :number;
  nonSpin? : number;
  ecrs? : number;  
 }

export type CopFields = keyof CopInterval;

// Base resource sent on the wire from the backend AI generated response
interface BaseAiResourceResult{
  resource : string;
  isLoad: boolean;
}
export interface AncillaryResourceResult extends BaseAiResourceResult {
  intervals : AncillaryInterval[];
  action : SubmissionAction;
}
export interface RealTimeResourceResult extends BaseAiResourceResult{
  intervals : RealTimeInterval[];
}
export interface CopResourceResult extends BaseAiResourceResult{
  intervals : CopInterval[];
}

export interface AncillaryInputRow {
  label: string 
  parent: string 
  child: string
  type: 'volume' | 'price'
  hourData: {[hour:number]: number }
}
export interface RealTimeInputRow {
  label:string, segment: number, type:'volume'|'price', hourData:{[key:number]: any}
} 
export interface RealTimeSegment {
  segment : number
  price : number
  volume : number
}
export interface CopInputRow{
  hourEnding: number
  data: CopInterval
}
export interface RealTimeInterval extends BaseIntervalData{
  segments?: RealTimeSegment[] 
}
export type RealTimeIntervals = RealTimeInterval[];

export interface hourproduct {
  hourEnding: number;
  product: string;
  value: number;
}

export type ResourceName = 
  'NOBLESLR_BESS1' | 'NOBLESLR_BESS2' | 'NOBLESLR_LD1' | 'NOBLESLR_LD2' |
  'CORALSLR_BESS1' | 'CORALSLR_BESS2' | 'CORALSLR_LD1' | 'CORALSLR_LD2';

/**
 * Represents bids or offers for a specific battery assets for specific hour ending intervals
 * This is sub-classed to allow specific interval offer structures for AS, COP and RT submissions.
 */
export interface BaseResourceSubmission<T = BaseIntervalData>{
  /**
   * Name of the resources being submitted (i.e. "NOBLESLR_LD1")
   */
  resource : ResourceName;
  intervals : T[];
  isLoad: boolean;
}

export type SubmissionAction =  "UpdateCreate" | "Delete";

export interface CopResourceSubmission extends BaseResourceSubmission<CopInterval>{}
export interface AncillaryResourceSubmission extends BaseResourceSubmission<AncillaryInterval>{
  action : SubmissionAction;
}
export interface RealTimeResourceSubmission extends BaseResourceSubmission<RealTimeInterval>{}

/**
 * Represents any possible resource submission for functions that need to handle / render
 * all transaction types
 */
export type AnyResourceSubmission = AncillaryResourceSubmission[] | RealTimeResourceSubmission[] | CopResourceSubmission[];

/**
 * Represents a submission of any of the document types
 */
export interface Submission{
  bidDate : string
  submittedBy : 'UserInterface' | 'AI'
  // True only if RT (and a load)
  isLoad?:boolean;
  // type : 'Ancillary' | 'RTMEnergyBid' | 'ThreePartOfferRT' | 'CurrentOperatingPlan';
  resourceSubmission : BaseResourceSubmission<CopInterval|AncillaryInterval|RealTimeInterval>[]
}
// Base class for the resources in the UI which are selected during the submission process
// Adds the `selected` and `active` properties which are discarded on save
export interface BaseUIResourceSubmission<T> extends BaseResourceSubmission<T>{
  /**
   * This is the presently selected resource (used for attributing input screen to resource on resource change)
   */
  active: boolean;
  /**
   * This resource has data and should be included in the submission / save action
   */
  selected : boolean;
}

export interface CopUIResource extends BaseUIResourceSubmission<CopInterval>{}
export interface AncillaryUIResource extends BaseUIResourceSubmission<AncillaryInterval> {
  action: SubmissionAction;
}

export interface RealTimeUIResource extends BaseUIResourceSubmission<RealTimeInterval> {}


// Function within each detail table row that allows UI to fetch the display value for said row
export type DetailRowGetValue = (d:any) => string | undefined;


export interface Override<T>{
  /**
   * The date of this override in format 'YYYY-MM-DD'
   */
  bidDate : string
  submissionType : OverrideType
  resourceSubmission : T[]
}
export type BaseOverride = Override<BaseResourceSubmission>
export type BaseResource = BaseResourceSubmission<BaseIntervalData>
export type BaseUIResource = BaseUIResourceSubmission<BaseIntervalData>;
export type AncillaryOverride = Override<AncillaryResourceSubmission>
export type CopOverride = Override<CopResourceSubmission>
export type RealtimeOverride = Override<RealTimeResourceSubmission>
export type CachedOverrides = {[date:string]: {ancillaries : AncillaryOverride, cop: CopOverride, realtime: RealtimeOverride}}

type OverrideType = SubType;
export {SubType as OverrideType};