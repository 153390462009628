import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { ConstructionComponent } from './components/construction/construction.component';
import { HomeComponent } from './components/home/home.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { BaseGuard } from './auth/base.guard';

const routes: Routes = [
  { 
    path: 'home', 
    component: HomeComponent,
    canActivate: [BaseGuard]
  },{
    path: '', 
    component: AppLayoutComponent,
    canActivate: [BaseGuard],
    children: [{
      path: 'voltaire',
      loadChildren: () => import('./components/voltaire/voltaire.module').then(m => m.VoltaireModule)
    }, {
      path: 'procurement',
      loadChildren: () => import('./components/procurement/procurement.module').then(m => m.ProcurementModule)
    }]
  }, {
    path: 'idera',
    canActivate: [BaseGuard],
    component: ConstructionComponent
  }, {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
