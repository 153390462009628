import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Module, ModulesResponse, PlayBookRes } from 'src/models/playbook/playbook';
import { uiTimezone } from '@app/components/voltaire/constants';

@Injectable()
export class Power{

    private _playbook! : PlayBookRes;

    get PlaybookResponse() {
        return this._playbook;
    }
    set PlaybookResponse(data: PlayBookRes) {
        this._playbook = data;
    }



    private _ActiveUser! : any;

    get ActiveUser() {
        return this._ActiveUser;
    }
    set ActiveUser(data: any) {
        this._ActiveUser = data;
    }
     // Get date offset from present day by parameter `add`
    getDate(add: number):string{
        const currentDate = new Date();
        const nextDay = new Date(currentDate);
        if(add !== 0){
            nextDay.setDate(currentDate.getDate() + add);
        }
        
        return this.formatDate(nextDay.toISOString());
    }

    // Format passed ISO data string as M-D-YYYY
    formatDate(isoDateString: string): string {
        const date1 = new Date(isoDateString);

        const cstDate = new Date(date1.toLocaleString('en-US', {timeZone: uiTimezone}));

        // Format it back to ISO string
        const cstIsoDateString = cstDate.toISOString();
       //formatting date to cst
        const date = new Date(cstIsoDateString);
      
        // Extract date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();
      
        // Create the formatted string
        const formattedDate = `${month}-${day}-${year}`;
      
        return formattedDate;
      }


}
