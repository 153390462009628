import pkg from '../../package.json';

/**
 * Share environment variables
 * Common to both production and development environments
 */

const hosts = {
    staging : {
        ui : ['nationalgridrenewables.azurewebsites.net'],
        api : 'treesappapi-dev.azurewebsites.net'
    }, 
    prod : {
        // Used to detect production published app
        ui : ['thecurrent.nationalgridrenewables.com','treesapp.azurewebsites.net'],
        api : 'treesappapi.azurewebsites.net'
    }
}

const isProd = hosts.prod.ui.includes(location.host); 

const theCurrent = '66ffe407-5ead-4f03-bbfe-858c477c96e6';
const ngrIt_dev = '5d6f9032-489f-48c3-82a4-4185641fb712';
export const environment = {
    appVersion : pkg.version,
    production: isProd,
    auth: {
        clientId: ngrIt_dev,
        tenantId: '33d1342a-ea34-4979-8d66-bed2d2d9231e'
    },
    // API of the backend services
    baseHref: `https://${isProd ? hosts.prod.api : hosts.staging.api}`,
}
