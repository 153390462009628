import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Signal } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, lastValueFrom, takeUntil, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ForecastDashBoard } from 'src/models/voltaire';

import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';

import { GraphAccountInfo, Tenaska } from '@models/api';
import { QueryRequest } from '@models/api/queries';
import { AvailabilityRequest, AwardsData, UnitAvailability } from '@models/api/tenaska';
import { AncillaryOverride, BaseOverride, BaseResource, CachedOverrides, CopOverride, RealtimeOverride, SubType, Submission } from '@models/submissions';
import { protectedResources } from 'src/app/auth/config';
import { environment } from 'src/environments/environment';
import { TenaskaData } from 'src/models/cosmos/cosmosresponse';
import { AwardSet } from 'src/models/ercot/ercotawards';
import { ModulesResponse, PlayBookRes } from 'src/models/playbook/playbook';
import { TenaskaQueryData } from 'src/models/tenaska/QueryResponse';
import { FormatDate } from '../utils';

import { BacktestService } from '@models/api/orval/interfaces/backtest';
import { GeneralService } from '@models/api/orval/interfaces/general';
import { OptimizerService } from '@models/api/orval/interfaces/optimizer';
import { TransactionsService } from '@models/api/orval/interfaces/transactions';
import { AppConfig, AppSettings, BacktestRequest, DeleteApiBacktestBacktestParams, DeleteApiBacktestStrategyParams, GetApiTransactionsParams, PostApiOptimizerGenerateTypeTargetDateParams, RequestType, Strategy, StrategyDeltaRequest, StrategyPlotRequest, SubmissionRequest, SubmissionType, TargetDate } from '@models/api/orval/schemas';
import { QueryClient, injectMutation, injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';

/**
 * API Service provides access to .NET backend services
 * 
 * @description
 * Each backend controller (`api/backtests`) should be mapped to a different namespace (i.e. `apiService.backtest.get()`)
 * 
 * Events:
 * {@link ApiService#beforeAction} fires before a HTTP POST or GET action is performed and can be used to clear prior error messages.
 * 
 * Key sections:
 * {@link ApiService#tenaska} - Proxied and formatted responses from Tenaska
 * 
 * 
 * Be sure to unsubscribe from actions in the consumer's destructor to avoid memory leaks.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {

  get loggedIn() {
    return this.activeAccount != undefined;
  } 
  /**
   * Contains the MSAL account info of the logged in user
   */
  activeAccount? : AccountInfo;

  /**
   * The /graph/me user profile fetched as separate transaction after validating user account
   */
  activeProfile? : GraphAccountInfo;

  private queryClient = injectQueryClient();
 
  private _overrides : CachedOverrides = {};

  private _overrides$ = new BehaviorSubject<BaseOverride[]|undefined>(undefined);

  private _beforeAction$ = new Subject<'post'|'get'|'delete'>();

  public beforeAction = this._beforeAction$.asObservable();

  /**
   * Get the overrides behavior pipe which will emit the starting collection of overrides and 
   * any updates to this collection
   */
  get overrides$() : Observable<AncillaryOverride[]>{
    // Safe to cast here as we filter out all undefined payloads
    return <Observable<AncillaryOverride[]>>this._overrides$.asObservable().pipe(filter( value => value != undefined));
  }

  /**
   * Backend URIs
   */
  private apiUris = {
    optimizer : "api/optimizer",
    overrides: "api/transactions/overrides",
  }
  
  private readonly _destroying$ = new Subject<void>();
  
  constructor(
    private generalService : GeneralService,
    private backtestService : BacktestService,
    private optimizerService : OptimizerService,
    private transactionService : TransactionsService,

    private httpClient: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
      this.initialize();
  }

  /**
   * Initialize MSAL and fetch server settings
   */
  private async initialize() {
    this.initMsal();
    
    try {
      this.overrides.getAsOverrides();
    }
    catch (e) {
      alert(`Error fetching overrides (see console)`);
      console.error(`Error fetching overrides`, e);
    }
  }

  /**
   * Setup listeners to update class state properties ({ApiService.loggedIn}) in response MSAL events
   */
  private initMsal() {
    this.msalService.instance.enableAccountStorageEvents();

    // This event fires more reliably than the broadcast service events in Chrome
    this.msalService.handleRedirectObservable().subscribe( { 
      next: (res) => this.checkAndSetActiveAccount(),
      error: (err) => console.error(`MSAL handleRedirectObservable error`, err)
    })

    // Enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.checkAndSetActiveAccount();
        }
      });

    // Several events will be emitted (startup, redirect).  The None event is the steady state where 
    // we should have an active user instance and be able to query authorized end points.
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((value:InteractionStatus) => {
        this.checkAndSetActiveAccount();
      });
  }

  /**
   * Query, set class property and log active account in response to MSAL login event
   */
  private async checkAndSetActiveAccount() {
    
    if(this.activeAccount){
      console.warn(`checkAndSetActiveAccount: already set, returning`);
      return;
    }
    
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      // Default to first logged into account 
      activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    if (activeAccount) {
      this.activeAccount = activeAccount;
      this.activeProfile = await firstValueFrom(this.httpClient.get<GraphAccountInfo>(protectedResources.msGraphMe.endpoint));
    }
  }

  /**
   * Login user via redirect method
   */
  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  /**
   * Logout user via redirect method
   */
  logoutRedirect() {
    this.msalService.logoutRedirect();
  }

  /**
   * Promisified version of `HttpClient.post` that prefixes appropriate backend server to URI.
   * Function signature matches that of `HttpClient.post()`
   */
  async post<T>(url:string, payload:any, options?: Parameters<typeof this.httpClient.post>[2]): Promise<T>{
    this._beforeAction$.next('post');
    return  firstValueFrom(this.httpClient.post<T>(`${environment.baseHref}/${url}`, payload, options));
  }
  async get<T>(url:string, options?: Parameters<typeof this.httpClient.post>[1]): Promise<T>{
    this._beforeAction$.next('get');
    return firstValueFrom(<Observable<T>>this.httpClient.get<T>(`${environment.baseHref}/${url}`, options));
  }
  async delete(url:string, options?: Parameters<typeof this.httpClient.post>[1]): Promise<any>{
    this._beforeAction$.next('delete');
    return firstValueFrom(<Observable<any>>this.httpClient.delete(`${environment.baseHref}/${url}`, options));
  }

  tenaska = {
    availability: (request:AvailabilityRequest):Observable<UnitAvailability[]> => {
      return this.httpClient.post<UnitAvailability[]>(`${environment.baseHref}/api/tenaska/availability`, request);
    },
    awards: (request:AvailabilityRequest):Promise<AwardsData[]> => {
      return lastValueFrom(this.httpClient.post<AwardsData[]>(`${environment.baseHref}/api/tenaska/awards`, request));
    },
    query: <T>(request: QueryRequest):Promise<Tenaska.QueryResponse<T>[]> => {
      return this.post<Tenaska.QueryResponse<T>[]>(`api/tenaska/query/submissions`, request);
    },
    queryRaw: <T>(request: QueryRequest):Promise<Tenaska.QueryResponse<T>[]> => {
      return this.post<Tenaska.QueryResponse<T>[]>(`api/tenaska/query/raw`, request);
    }
  }

  getVoltaireForeCastData(): Observable<ForecastDashBoard[]> {
    return this.httpClient.get<ForecastDashBoard[]>(environment.baseHref + "/Forecast/GetForeCastData").pipe(
      catchError(this.handleError)
    );
  }

  getVoltaireForeCastAncillaryData(startDate : Date): Promise<ForecastDashBoard[]> {
    return this.get<ForecastDashBoard[]>("Forecast/GetForeCastAncillaryData", {
      params: {
        startDate: FormatDate.YMD(startDate)
      }
    });
  }

  getPlayBookData(): Observable<PlayBookRes> {
    return this.httpClient.get<PlayBookRes>(environment.baseHref + "/GetPlayBookData").pipe(
      catchError(this.handleError)
    );
  }

  
  getModules(): Observable<ModulesResponse> {
    return this.httpClient.get<ModulesResponse>(environment.baseHref + "/GetModules").pipe(
      catchError(this.handleError)
    );
  }

  getDataFromCosmos(container : any): Observable<any> {
    return this.httpClient.post<string>(environment.baseHref + "/GetDatafromCosmos", container);
  }

  postScenarioData(data:any): Observable<any>{
    return this.httpClient.post<string>(environment.baseHref + "/SaveScenarioData", data);
  }
  

  queryTenaska(data: any): Observable<TenaskaQueryData> {
    let z = {} as TenaskaQueryData;
    return this.httpClient.post<TenaskaQueryData>(environment.baseHref + "/api/tenaska/query/raw", data);
  }

  getErcotAwards(data: any): Observable<AwardSet> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', // Set the correct content type
      }),
    };

    return this.httpClient.post<AwardSet>(environment.ercotHref + "/getErcotAwards", data, httpOptions);
  }

   voltaire = {
    /**
     * Get the payload for an Angular `injectQuery()` call which must be executed 
     * in the remote class context for signal tracking to work.
     */
    getForecastQuery : (date:Signal<Date>) => injectQuery(() => ({
        queryKey: ['voltaireAncillaryForecast', {date:date()}],
        queryFn: () => this.getVoltaireForeCastAncillaryData(date())
      }))
  }

  /**
   * Listing and generating transactions
   */
  transactions = {
    submit: {
      [SubType.ancillary] : (data: Submission)=>this.post<TenaskaData[]>("api/tenaska/submit/ancillaries", data),
      [SubType.cop]: (data: Submission) => this.post<TenaskaData[]>("api/tenaska/submit/cop", data),
      [SubType.realTime] : (data: Submission) => this.post<TenaskaData[]>("api/tenaska/submit/realtime", data)
    },
    getQuery: (params:Signal<GetApiTransactionsParams|undefined>, enabled:Signal<boolean>) => injectQuery(() => ({
      queryKey:['transactions', {...params()}],
      queryFn: () => {
        return firstValueFrom(this.transactionService.getApiTransactions(params()))
      },
      enabled: enabled() && !!params()
    })),
    /** For accessing machine generated transactions */
    ai:{
      getSubmitMutation : () => injectMutation((client) => ({
        mutationFn: (data:SubmissionRequest) => firstValueFrom(this.optimizerService.postApiOptimizerSubmit(data)),
        onSuccess: (result, params:SubmissionRequest) => {

          // TODO: Make input to backend submission function match the documentType property
          const map : {[index in SubmissionType]: RequestType } = {
            [SubmissionType.ancillary] : RequestType.ancillaries,
            [SubmissionType.currentOperatingPlan] : RequestType.cop,
            [SubmissionType.energy] : RequestType.realtime
          }
          // TODO: Cosmos stores ENUM with capital first, JSON serialization is lower first
          // Standardize all of the submission types and container names
          let type = map[params.submissionType] || map[<SubmissionType>_.lowerFirst(params.submissionType)];
          
          let p : GetApiTransactionsParams = { IsAI: true, RequestType: type };
          client.invalidateQueries({queryKey:['transactions', p]}) 
        } 
      })),
      /** Get a mutation to generate remote transaction documents */
      getGenerateMutation: () => injectMutation((client:QueryClient) => ({
        mutationFn: (data: {type:RequestType, target: TargetDate, params?: PostApiOptimizerGenerateTypeTargetDateParams}) => firstValueFrom(this.optimizerService.postApiOptimizerGenerateTypeTargetDate(data.type, data.target, data.params)),
        onSuccess: (result, params) => {
          let p : GetApiTransactionsParams = {
            IsAI: true,
            RequestType: params.type
          };
          client.invalidateQueries({queryKey:['transactions', p]});
        }
      }))
    }
  }

  overrides = {
    /**
     * Updates or creates an override for the date and submission type in question.
     * If overrides already exist, then overwrite just the specified elements of the resource submission array.
     */
    updateOverrides:async (bidDate:string, submissionType: SubType, resources:BaseResource[]) => {
      const override = this.overrides.getLocalItem(bidDate, submissionType);
      
      let submitResources : Record<string, BaseResource> = {};
      if(override){
        submitResources = override.resourceSubmission.reduce( (a,c) => ({...a, [c.resource] : c }) , {});
      }
      submitResources = {...submitResources, ..._.mapKeys(resources, r=> r.resource)}

      let submission : BaseOverride = {
        ...override,
        submissionType,
        bidDate: FormatDate.UITimezone(bidDate, 'YYYY-MM-DD'),
        resourceSubmission: Object.values(submitResources)
      }

      return await this.overrides.saveAsOverride(submission);
    },
    /**
     * Fetch requested item from cache without going back to server
     */
    getLocalItem:(date:Date | string, typeKey:SubType): AncillaryOverride | CopOverride | RealtimeOverride |  undefined  => {
      const dateKey = FormatDate.UITimezone(date, 'YYYY-MM-DD');
      return this._overrides[dateKey]?.[typeKey];
    },
    saveAsOverride : async (data : BaseOverride) => {
      let res = await this.post<BaseOverride>(`${this.apiUris.overrides}/${data.submissionType}`, data);
      _.set(this._overrides, `${res.bidDate}.${data.submissionType}`, res); 
      this._overrides$.next([res]);
      return res;
    },
    getAsOverrides : async ()=>{
      let res = await this.get<AncillaryOverride[]>(`${this.apiUris.overrides}/future`);
      this._overrides = {};
      for(let o of res){
        _.set(this._overrides, `${o.bidDate}.${o.submissionType}`, o); 
      }
      this._overrides$.next(res);
      return this._overrides;
    },
  }

  /**
   *  All RPCs relating to the backtest service
   */
  backtest = {
    strategies: {
      getAllQuery: () => injectQuery(() => ({
        queryKey:['strategies'],
        queryFn: () => firstValueFrom(this.backtestService.getApiBacktestStrategies())
      })),
      getUpdateMutation: () => injectMutation((client:QueryClient) => ({
        mutationFn: (val: Strategy) => firstValueFrom(this.backtestService.postApiBacktestStrategy(val)),
        onSuccess: () => client.invalidateQueries({queryKey:['strategies']})
      })),
      getDeleteMutation: () => injectMutation((client:QueryClient) => ({
        mutationFn: (params: DeleteApiBacktestStrategyParams) => firstValueFrom(this.backtestService.deleteApiBacktestStrategy(params)),
        onSuccess: () => client.invalidateQueries({queryKey:['strategies']})
      }))
    },
    getQuery : () => injectQuery(() => ({
      queryKey: ['backtests'],
      queryFn: () => firstValueFrom(this.backtestService.getApiBacktestAll())
    })),
    /** Mutation to submit new backtest */
    getSubmitMutation: () => injectMutation((client:QueryClient) => ({
      mutationFn: (data:BacktestRequest) => firstValueFrom(this.backtestService.postApiBacktestSubmit(data)),
      onSuccess: () => client.invalidateQueries({queryKey:['backtests']})
    })),
    /** Mutation to get / advance status of existing backtest */
    getStatusMutation: () => injectMutation((client:QueryClient) => ({
      mutationFn: (...args:Parameters<BacktestService['postApiBacktestQuery']>) => firstValueFrom(this.backtestService.postApiBacktestQuery(...args)),
      onSuccess: () => client.invalidateQueries({queryKey:['backtests']})
    })),
    /** Get mutation which deletes specified backtest */
    getDeleteMutation: () => injectMutation((client:QueryClient) => ({
      mutationFn: (params:DeleteApiBacktestBacktestParams) => firstValueFrom(this.backtestService.deleteApiBacktestBacktest(params)),
      onSuccess: () => client.invalidateQueries({queryKey:['backtests']})
    })),
    getStrategyData : ( request : StrategyPlotRequest ) => firstValueFrom(this.backtestService.postApiBacktestStrategiesHourly(request)),
    getStrategyDelta : (request : StrategyDeltaRequest) => firstValueFrom(this.backtestService.postApiBacktestStrategiesDelta(request))
  };
  config = {
    getQuery : () => injectQuery(() => ({
      queryKey:['appConfig'],
      queryFn: () => firstValueFrom(this.generalService.getApiGeneralConfig()),
    })),
    getData: () => this.queryClient.fetchQuery<AppConfig>({
      queryKey:['appConfig'],
      queryFn: () => firstValueFrom(this.generalService.getApiGeneralConfig()),
    })
  };
  /**
   * Angular query objects to fetch and mutate the app settings
   */
  settings = {
    getQuery : () => injectQuery(() => ({
      queryKey:['appSettings'],
      queryFn: () => firstValueFrom(this.generalService.getApiGeneralSettings()),
    })),
    getMutation : () => injectMutation((client) => ({
      mutationFn: (data:AppSettings) => firstValueFrom(this.generalService.postApiGeneralSettings(data)),
      onSuccess: () => client.invalidateQueries({queryKey:['appSettings']})
    }))
  }

  private handleError(err: any) {
    console.error(err);
    console.error(err.error);
    return throwError(err.error);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
