/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type ActionType = typeof ActionType[keyof typeof ActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  updateCreate: 'updateCreate',
  delete: 'delete',
} as const;
