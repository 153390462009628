/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  Backtest,
  BacktestRequest,
  DeleteApiBacktestBacktestParams,
  DeleteApiBacktestStrategyParams,
  PostApiBacktestQueryBody,
  Strategy,
  StrategyDeltaRequest,
  StrategyDeltaResponse,
  StrategyHourlyResponse,
  StrategyPlotRequest
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class BacktestService {
  constructor(
    private http: HttpClient,
  ) {} postApiBacktestStrategy<TData = Strategy>(
    strategy: Strategy, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/backtest/strategy`,
      strategy,options
    );
  }
 deleteApiBacktestStrategy<TData = void>(
    params?: DeleteApiBacktestStrategyParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `/api/backtest/strategy`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Get strategies with backtest runs linked into them
 */
 getApiBacktestStrategies<TData = Strategy[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/backtest/strategies`,options
    );
  }
/**
 * @summary Fetch all backtests from system while nulling out data field to save on transfer
 */
 getApiBacktestAll<TData = Backtest[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/backtest/all`,options
    );
  }
 deleteApiBacktestBacktest<TData = void>(
    params?: DeleteApiBacktestBacktestParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `/api/backtest/backtest`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Create and submit a new backtest request
 */
 postApiBacktestSubmit<TData = Backtest>(
    backtestRequest: BacktestRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/backtest/submit`,
      backtestRequest,options
    );
  }
/**
 * @summary Advance backtest to next stage of request cycle.
1. Non-submitted: Submit new backtest
2. Submitted: request status.
3. Succeeded (and no `data`): download data.
 */
 postApiBacktestQuery<TData = Backtest>(
    postApiBacktestQueryBody: PostApiBacktestQueryBody, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('backtestId', postApiBacktestQueryBody.backtestId)

    return this.http.post<TData>(
      `/api/backtest/query`,
      formData,options
    );
  }
/**
 * @summary For the requested list of strategies return all backtests that have been run against that strategy.
 */
 postApiBacktestStrategiesHourly<TData = StrategyHourlyResponse[]>(
    strategyPlotRequest: StrategyPlotRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/backtest/strategies/hourly`,
      strategyPlotRequest,options
    );
  }
 postApiBacktestStrategiesDelta<TData = StrategyDeltaResponse[]>(
    strategyDeltaRequest: StrategyDeltaRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/backtest/strategies/delta`,
      strategyDeltaRequest,options
    );
  }
};

export type PostApiBacktestStrategyClientResult = NonNullable<Strategy>
export type DeleteApiBacktestStrategyClientResult = NonNullable<void>
export type GetApiBacktestStrategiesClientResult = NonNullable<Strategy[]>
export type GetApiBacktestAllClientResult = NonNullable<Backtest[]>
export type DeleteApiBacktestBacktestClientResult = NonNullable<void>
export type PostApiBacktestSubmitClientResult = NonNullable<Backtest>
export type PostApiBacktestQueryClientResult = NonNullable<Backtest>
export type PostApiBacktestStrategiesHourlyClientResult = NonNullable<StrategyHourlyResponse[]>
export type PostApiBacktestStrategiesDeltaClientResult = NonNullable<StrategyDeltaResponse[]>
