import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

 import {
   HttpEvent,
   HttpInterceptor,
   HttpHandler,
   HttpRequest,
 } from '@angular/common/http';

 import { Observable } from 'rxjs';

 @Injectable()
 export class APIInterceptor implements HttpInterceptor {

   intercept(
     req: HttpRequest<any>,
     next: HttpHandler,
   ): Observable<HttpEvent<any>> {
    const baseUrl = new URL(environment.baseHref);
    
    if(!req.url.startsWith('http')){
        baseUrl.pathname = req.url;
        const apiReq = req.clone({ url: baseUrl.toString() });
        return next.handle(apiReq);
    }
    return next.handle(req);
   }
 }
