import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MSAL_INSTANCE, MsalService, MsalBroadcastService, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalGuard, MsalRedirectComponent, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor } from '@azure/msal-angular';
import { HomeComponent } from './components/home/home.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType, } from '@azure/msal-browser';
import { SafePipe } from './safe.pipe';
import { providers as appServices } from './services';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';
import { AppLayoutModule } from './layout/app.layout.module';
import { loginRequest, msalConfig, protectedResources } from './auth/config';
import { BaseGuard } from './auth/base.guard';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharedModule } from './shared.module';
import { APIInterceptor } from './http.injector';
import { providers as backendServices } from '@models/api/orval';
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { UserSettingsService } from './services/userSettings';

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Configure the routes which when called via `httpClient.get()` MSAL will automatically inject the "Bearer ..."
 * authorization header.  Without entries in `protectedResourceMap` you will likely receive 401 errors. 
 */
function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  for(let resource of Object.values(protectedResources)){
    protectedResourceMap.set(resource.endpoint, resource.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: loginRequest.scopes,
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SafePipe,
    ],
  imports: [
    SharedModule, 
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MsalModule,
    ReactiveFormsModule,
    AppLayoutModule,
    AngularQueryDevtools
],
  providers: [
    provideHttpClient(withFetch()),
    provideAngularQuery(new QueryClient()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue:{
        lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml')
        },
        themePath: 'assets/styles/highlightjs/androidstudio.min.css'
      }
    },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    BaseGuard,
    UserSettingsService,
    ConfirmationService,
    MessageService,
    ...backendServices,
    ...appServices
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
}
)

export class AppModule { }
