import { Injectable, effect, signal } from '@angular/core';
import { UserSettingsService } from '@app/services/userSettings';
import { Chart } from 'chart.js';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface AppConfig {
    inputStyle: string;
    colorScheme: string;
    theme: string;
    ripple: boolean;
    menuMode: 'static' | 'overlay';
    scale: number;
}

interface LayoutState {
    // True to hide the static menu
    staticMenuDesktopInactive: boolean;
    // True to show the temporary overlay menu
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    private readonly defaultTheme = 'saga-green';
    private readonly darkTheme = 'md-dark-indigo';
    private currentTheme: string = this.defaultTheme;
    private darkModeSignal = signal(false);
    /**
     * Signal which contains present dark mode preference and fires when user changes
     */
    get dark(){ 
        return this.darkModeSignal.asReadonly();
    }
  
    _config: AppConfig = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: this.defaultTheme,
        scale: 14,
    };
    
    config = signal<AppConfig>(this._config);

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: true,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
    };

    private configUpdate = new Subject<AppConfig>();

    private overlayOpen = new Subject<any>();

    /**
     * Text colors used in light and dark modes in ChartJS axis
     */
    private chartColors = {
        light : 'black',
        dark : 'white'
    }

    configUpdate$ = this.configUpdate.asObservable();

    overlayOpen$ = this.overlayOpen.asObservable();

    constructor(
        private userSettings : UserSettingsService
    ) {
        effect(() => {
            const config = this.config();
            this.changeScale(config.scale);
            this.onConfigUpdate();
        });

        this.state.staticMenuDesktopInactive = !this.userSettings.staticMenuVisible;
        if(this.userSettings.darkMode){
            this.changeThemes(this.darkTheme);
            this.darkModeSignal.set(true);
        }
    }

    /**
     * Toggle between dark and light themes
     */
    toggleDarkMode() {
        const darkMode = (this.currentTheme == this.darkTheme);
        this.changeThemes(darkMode ? this.defaultTheme : this.darkTheme);
        this.darkModeSignal.set(this.currentTheme == this.darkTheme);
        this.userSettings.darkMode = (this.currentTheme == this.darkTheme);
    }

    updateStyle(config: AppConfig) {
        return (
            config.theme !== this._config.theme ||
            config.colorScheme !== this._config.colorScheme
        );
    }

    hideLeftMenu(){
        this.onMenuToggle();
    }

    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;
            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
            this.userSettings.staticMenuVisible = !this.state.staticMenuDesktopInactive;
        } else {
            this.state.staticMenuMobileActive =
                !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    showProfileSidebar() {
        this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
        if (this.state.profileSidebarVisible) {
            this.overlayOpen.next(null);
        }
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isOverlay() {
        return this.config().menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        this._config = { ...this.config() };
        this.configUpdate.next(this.config());
    }

    // Function to change the theme
    changeThemes(newTheme: string): void {
        this.currentTheme = newTheme;

        // You can also update the theme dynamically in the DOM if needed
        const themeLink = document.getElementById('theme-css');
        if (themeLink) {
            themeLink.setAttribute('href', `assets/layout/styles/theme/${newTheme}/theme.css`);
        }
    }

    // changeTheme() {
    //     const config = this.config();
    //     const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    //     const themeLinkHref = themeLink.getAttribute('href')!;
    //     const newHref = themeLinkHref
    //         .split('/')
    //         .map((el) =>
    //             el == this._config.theme
    //                 ? (el = config.theme)
    //                 : el == `theme-${this._config.colorScheme}`
    //                 ? (el = `theme-${config.colorScheme}`)
    //                 : el
    //         )
    //         .join('/');

    //     this.replaceThemeLink(newHref);
    // }
    replaceThemeLink(href: string) {
        const id = 'theme-css';
        let themeLink = <HTMLLinkElement>document.getElementById(id);
        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(
            cloneLinkElement,
            themeLink.nextSibling
        );
        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    changeScale(value: number) {
        document.documentElement.style.fontSize = `${value}px`;
    }

   

    /**
    * Utility function to the set the legend and X / Y axis tick colors on the passed Chart object
    */
    setChartColors(chart:Chart | null, darkMode: boolean){
        const legendColor = darkMode ? this.chartColors.dark : this.chartColors.light;
        if(chart){
            if(chart.options.plugins?.legend?.labels){
                chart.options.plugins.legend.labels.color = legendColor;
            }
            if(chart.options.scales?.['x']?.ticks?.color){
                chart.options.scales['x'].ticks.color = legendColor;
            }
            if(chart.options.scales?.['y']?.ticks?.color){
                chart.options.scales['y'].ticks.color = legendColor;
            }
            chart.update();
        }
    }
}
