import { Component, isDevMode, OnInit } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  mode = new FormControl('over' as MatDrawerMode);
  title = 'nGRIT';
  opened = false;
  isExpanded = false;
  isShowing = false;
  idtoken: string = '';

  showDevTools = isDevMode();

  toggleSidenav() {
    this.opened = !this.opened;
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

}
