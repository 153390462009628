
import { BacktestService } from "./interfaces/backtest";
import { GeneralService } from "./interfaces/general";
import { OptimizerService } from "./interfaces/optimizer";
import { ProxyService } from "./interfaces/proxy";
import { TenaskaService } from "./interfaces/tenaska";
import { TransactionsService } from "./interfaces/transactions";
import { AppSettings } from "./schemas";

export const providers = [
    GeneralService,
    BacktestService,
    OptimizerService,
    ProxyService,
    TenaskaService,
    TransactionsService
];

// Derived convenience types
export type ServiceType = keyof Omit<AppSettings, 'id'>;