import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { YesEnergyLamp } from 'src/models/ercot/yesenergy';
import { environment } from 'src/environments/environment';
import { FormatDate } from '../utils';

@Injectable()
export class YesEnergyService {

  private readonly yesLocalApi = "/api/proxy/yes";
  private readonly yesRemoteApi = "/PS/rest/timeseries/multiple.json";

  constructor(private httpClient: HttpClient) { }

  /**
   * Make a request to YES Energy API with the passed parameters 
   */
  private get(config : {date: Date, items: string[]}){
    let requestUrl = new URL(environment.baseHref);
    requestUrl.pathname = `${this.yesLocalApi}${this.yesRemoteApi}`;
  
    requestUrl.searchParams.append('agglevel', 'hour');
    requestUrl.searchParams.append('items', config.items.join(','));
    requestUrl.searchParams.append('startdate', FormatDate.YMD(config.date));
    // Note: YES API requires start and end date the SAME (for 24 hours of data)
    requestUrl.searchParams.append('enddate', FormatDate.YMD(config.date));

    return this.httpClient.get<YesEnergyLamp[]>(requestUrl.toString());
  }

  /**
   * Get data from YES energy for 24 hour period from start date
   * @param startDate The date for which to fetch data.
   * @returns API response as Observable
   */
  getYesEnergyLAMP(startDate: Date): Observable<YesEnergyLamp[]> {
    return this.get({date: startDate, items: ['RT_LMP_ERCOT_ADDERS;NOBLESLR_ALL']});
  }
  /**
   * Get the following ERCOT items (i.e. appends ERCOT location suffix to each in the request) 
   */
  getErcotItems(startDate: Date, items:string[]): Observable<YesEnergyLamp[]>{
    let locationERCOT = '10000756298';
    return this.get({date: startDate, items: items.map(i => `${i}:${locationERCOT}`)});
  }

}
