import { Component, EnvironmentInjector, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    showPluto= false;
//  plutoUrl= environment.plutoUrl;
 
  constructor(private msalService: MsalService) { }

  ngOnInit(): void {
   
   // const account = this.m
   // this.plutoUrl = environment.plutoUrl;
 // this.msalService.loginRedirect();
  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logout();
  }
}
