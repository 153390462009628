/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type SubmissionType = typeof SubmissionType[keyof typeof SubmissionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubmissionType = {
  ancillary: 'ancillary',
  energy: 'energy',
  currentOperatingPlan: 'currentOperatingPlan',
} as const;
