import { Injectable } from '@angular/core';
import { ProxyService } from '@models/api/orval/interfaces/proxy';
import { Observable } from 'rxjs';
import { IgnitionLatest, TagHistoric } from 'src/models/ignition';
import { FormatDate, daysOffset } from '../utils';

@Injectable()
export class IgnitionService {

  constructor(private proxy : ProxyService) { }

  /**
   * Get the latest readings (one per tag) for the specified tags
   * @param tags The Ignition path tags to fetch
   * @param endDate Optional end date to fetch latest readings at a point in the past
   * (i.e. specify 4/3/2023 and latest reading will be 4/3/2023 00:00)
   */
  getReadingsLatest(tags: string[], endDate? : Date){
    let params : any = {'tags': tags.join(';')};
    if(endDate){
      params['endDate'] = FormatDate.YMD(endDate);
    }
    return this.proxy.getApiProxyIgnitionPath<IgnitionLatest>('latest', {params})
  }
  /**
   * Get the readings for the 24 hours in the present day (in local time) for the specified tags
   * @param tags 
   * @returns 
   */
  getDailyData(tags:string[], requestDate : Date = daysOffset(0)): Observable<TagHistoric[]>{
    let params = {
      start: FormatDate.YMD(requestDate),
      end: FormatDate.YMD(daysOffset(1, requestDate)),
      tags: tags.join(';')
    }
    return this.proxy.getApiProxyIgnitionPath<TagHistoric[]>('historic', {params});
  }
}
