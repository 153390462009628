import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { ApiService } from '../services';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];

    Icons = PrimeIcons;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public apiService: ApiService,
        public layoutService: LayoutService
    ) { }
}
