/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type BacktestStatus = typeof BacktestStatus[keyof typeof BacktestStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BacktestStatus = {
  submitted: 'submitted',
  success: 'success',
  downloaded: 'downloaded',
  error: 'error',
} as const;
