import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HighlightModule } from "ngx-highlightjs";
import { MomentModule } from "ngx-moment";
import { MiscPipes } from "./misc.pipe";
import { PrimeNgModule } from "./primeng.module";
import { CstDatePipe, SentenceCasePipe, TypedKeyValuePipe } from "./utils.pipe";


@NgModule({
    imports:[
        CommonModule,
        MomentModule,
        HighlightModule,
        MiscPipes,
        PrimeNgModule
    ],
    declarations:[
        CstDatePipe,
        SentenceCasePipe,
        TypedKeyValuePipe,
    ],
    exports:[
        CommonModule,
        FormsModule,
        MomentModule,
        CstDatePipe,
        TypedKeyValuePipe,
        SentenceCasePipe,
        HighlightModule,
        MiscPipes,
        PrimeNgModule
    ]
})
export class SharedModule{}