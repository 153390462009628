import { Injectable } from "@angular/core";

/**
 * Persist user preferences to local storage.
 * Later, may persist to server storage account to share between sessions
 */
@Injectable()
export class UserSettingsService{

  private readonly keys = [
    'darkMode',
    'staticMenuVisible'
  ] as const;

  private get<T>(key:typeof this.keys[number], defaultVal:T):T{
    let val = localStorage.getItem(key);
    if(val !== null){
      return JSON.parse(val);
    }
    return defaultVal;
  }

  private set<T>(key:typeof this.keys[number], val:T){
    localStorage.setItem(key, JSON.stringify(val));
  }

  get staticMenuVisible(){
    return this.get<boolean>('staticMenuVisible', true);
  }
  set staticMenuVisible(val:boolean){
    this.set<boolean>('staticMenuVisible', val);
  }

  get darkMode(){
    return this.get<boolean>('darkMode', false);
  }
  set darkMode(val){
    this.set<boolean>('darkMode', val);
  }

}