import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';

@Pipe({
    name: 'toWordsAgo'
})
export class ToWordsAgo implements PipeTransform {
    transform(value: any, ...args: any[]) {
        return formatDistance(value, new Date());
    }
}

@NgModule({
    declarations:[
        ToWordsAgo
    ],
    imports: [CommonModule],
    exports:[
        ToWordsAgo
    ]
})
export class MiscPipes{}