/**
 * Types for the `/api/tenaska` endpoints in the .NET WebAPI backend 
 */

import { AncillaryInterval } from "@models/submissions"

export enum QueryAsset { 
    Noble1 = 'NOBLE1', 
    Noble2 = 'NOBLE2'
} 

export interface QueryTarget{
    name : QueryAsset
    isLoad : boolean
}

export interface AvailabilityRequest{
    /**
     * Format YYYY-MM-DD
     */
    startDate : string
    assets : QueryTarget[]
}
export interface AvailabilityInterval{
    hourEnding : number
    volume : number
}
// Describes the availability of a single unit 
export type UnitAvailability = { 
    asset: QueryTarget
    capacity: number
    intervals : AvailabilityInterval[]
};
export type AwardsData = {
    asset: QueryTarget
    intervals: AncillaryInterval[]
};