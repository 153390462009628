/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  AncillaryResourceSubmissionOverride,
  CopResourceSubmissionOverride,
  GetApiTransactionsParams,
  ObjectIAsyncEnumerable,
  RealTimeResourceSubmissionOverride
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class TransactionsService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * @summary Fetch transactions of specified type for specified date ranges (or all transactions if no range specified).
 */
 getApiTransactions<TData = ObjectIAsyncEnumerable>(
    params?: GetApiTransactionsParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Overwrite the ancillary override (if it exists) for the day in question with the information provided.
 */
 postApiTransactionsOverridesAncillaries<TData = void>(
    ancillaryResourceSubmissionOverride: AncillaryResourceSubmissionOverride, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/transactions/overrides/ancillaries`,
      ancillaryResourceSubmissionOverride,options
    );
  }
 postApiTransactionsOverridesCop<TData = void>(
    copResourceSubmissionOverride: CopResourceSubmissionOverride, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/transactions/overrides/cop`,
      copResourceSubmissionOverride,options
    );
  }
 postApiTransactionsOverridesRealtime<TData = void>(
    realTimeResourceSubmissionOverride: RealTimeResourceSubmissionOverride, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/transactions/overrides/realtime`,
      realTimeResourceSubmissionOverride,options
    );
  }
/**
 * @summary Fetch overrides for today and all future dates
 */
 getApiTransactionsOverridesFuture<TData = ObjectIAsyncEnumerable>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/overrides/future`,options
    );
  }
};

export type GetApiTransactionsClientResult = NonNullable<ObjectIAsyncEnumerable>
export type PostApiTransactionsOverridesAncillariesClientResult = NonNullable<void>
export type PostApiTransactionsOverridesCopClientResult = NonNullable<void>
export type PostApiTransactionsOverridesRealtimeClientResult = NonNullable<void>
export type GetApiTransactionsOverridesFutureClientResult = NonNullable<ObjectIAsyncEnumerable>
